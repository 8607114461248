import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Button, Navbar, Container, Card } from 'react-bootstrap';

import web3Modal from './modal.js'
import ContractArtifact from './assets/Artifact.json';

import CardLogIn from './CardLogIn'
import CardPublicMint from './CardPublicMint'
import CardMessage from './CardMessage'
import CardThankYou2 from './CardThankYou2'

const CHAIN_IDS = [1, '0x1']

export default function App() {
  const [status, setStatus] = useState("init");
  const PENDING_STATUSES = ["signing", "pending", "failed", "unknownError"];
  const SUCCESS_STATUSES = ["success", "formFilled"]

  const [instance, setInstance] = useState();
  const [provider, setProvider] = useState();
  const [signer, setSigner] = useState();
  const [address, setAddress] = useState();
  const [chainId, setChainId] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [contract, setContract] = useState();
  const [mintStatus, setMintStatus] = useState();
  const [mintPrice, setMintPrice] = useState();
  const [tokensRemaining, setTokensRemaining] = useState();
  const [tokenIds, setTokenIds] = useState();

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
   }, [])

   useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAddress(accounts[0]);
      };

      const handleChainChanged = (chainId) => {
        setChainId(chainId);
      };

      instance.on("accountsChanged", handleAccountsChanged);
      instance.on("chainChanged", handleChainChanged);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
        }
      };
    }
  }, [provider, instance]);

  const connectWallet = async () => {
    try {
      const instance = await web3Modal.connect();
      setInstance(instance)

      const provider = new ethers.providers.Web3Provider(instance);
      setProvider(provider)

      const accounts = await provider.listAccounts();
      if (accounts) setAddress(accounts[0])
      
      const network = await provider.getNetwork();
      setChainId(network.chainId);

      const signer = provider.getSigner(accounts[0]);
      setSigner(signer);

      const contract = new ethers.Contract(
        '0x78D72E60BaE892F97b97fEBAE5886DaB2eF0cbC8',
        ContractArtifact.abi,
        provider
      )
      setContract(contract)

      const tierInfo = await contract.getTierInfo(0);
      setMintPrice(ethers.utils.formatEther(tierInfo.price.toString()));
      setMintStatus(tierInfo.mintStatus);
      setTokensRemaining(tierInfo.maxId - tierInfo.nextId + 1);
    } catch (error) {
      console.error(error);
    }
  }

  const getMessage = {
    closed: {header: 'Join the Pig Pen', message: 'Minting is currently closed. Sign up for the waitlist at capitalism.com/pig!'},
    wrongChain: {header: 'Wrong Network', message: 'Please switch to Ethereum Mainnet to mint.'},
    signing: {header: 'Transaction in Process', message: 'Please sign your transaction in your browser wallet.'},
    pending: {header: 'Your Order is Being Processed', message: 'Do not close this page. Assuming network traffic is reasonable, everything should be confirmed in about 15 seconds.'},
    unknownError: errorMessage
  }

  const publicMint = async (quantity) => {
    setStatus('signing')
    let tx;
    try {
      tx = await contract.connect(signer).mintPublic(0, quantity, {value: ethers.utils.parseEther(mintPrice).mul(quantity)})
      setStatus('pending')
    } catch (err) {
      console.log(err)
      setErrorMessage({
        header: 'Transaction Failed',
        message: err.error.message
      })
      setStatus('unknownError')
      return
    }
    
    const receipt = await tx.wait()
    _processReceipt(receipt);
  }

  const _processReceipt = (receipt) => {
    if (receipt.status === 0) {
      setStatus('unknownError')
    } else {
      try {
        let tokenIds = [];
        const events = receipt.events;
        for (let i = 0; i < events.length; i++) {
          tokenIds.push(events[i].args.tokenId.toNumber());
        }
        setTokenIds(tokenIds)
      } catch (err) {}
      setStatus('success')
    }
  }
 
  return (
    <div className="App">
      <div style={{ backgroundImage:`url(/background.png)`, backgroundRepeat:"no-repeat", backgroundSize:"cover", height: '100vh', width: '100vw' }}>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="/">Capitalist Pigs</Navbar.Brand>
            <Navbar.Text>{address && address.length ? 'Signed In As: ' + address.slice(0, 4) + '....' + address.slice(-4) : <Button variant="light" onClick={connectWallet}>Connect Wallet</Button>}</Navbar.Text>
          </Container>
        </Navbar>

        {/* removed below checks, add back if needed: !address || mintStatus === 0 ||  */}
        {!SUCCESS_STATUSES.includes(status) ?  
        
          <Card style={{ width: '32rem', margin: 'auto', marginTop: '8rem', zIndex: '1' }}>
            <Card.Body>
              <>{!address ? <CardLogIn /> : ""}</>
              <>{address && !CHAIN_IDS.includes(chainId) ? <CardMessage message={getMessage["wrongChain"]} /> : ""}</>
              <>{address && CHAIN_IDS.includes(chainId) && mintStatus === 0 ? <CardMessage message={getMessage["closed"]} /> : ""}</>
              <>{address && CHAIN_IDS.includes(chainId) && mintStatus === 2 && status === "init" ? <CardPublicMint tokensRemaining={tokensRemaining} publicMint={publicMint} mintPrice={mintPrice} /> : ""}</>
              <>{address && CHAIN_IDS.includes(chainId) && mintStatus === 2 && PENDING_STATUSES.includes(status) ? <CardMessage message={getMessage[status]} /> : ""}</>
            </Card.Body>
          </Card>

          : 
          <Card style={{ width: '48rem', margin: 'auto', marginTop: '4rem', zIndex: '1' }}>
            <Card.Body>
                <CardThankYou2 tokenIds={tokenIds} contractAddr={contract.address} />
            </Card.Body>
          </Card>
          }
      </div>
    </div>
  );
}