import { Card, Spinner } from 'react-bootstrap';

export default function CardLogIn() {
    return (
        <div>
            <Card.Header as="h3" style={{textAlign: 'center', marginBottom: '1rem' }}>Please Connect Your Wallet</Card.Header>
            <div style={{ marginLeft: '45%', marginTop: '2rem', marginBottom: '2rem' }}>    
            <Spinner animation="border" role="status"></Spinner>
            </div>
            <Card.Text>
                - Don't have a crypto wallet? <a href="https://www.loom.com/share/0f133833ee58432eb1e0a4266646740a" target="_blank" rel="noopener noreferrer">Watch this setup video.</a><br />
                - Need more help? <a href="mailto:ryan@capitalism.com" target="_blank" rel="noopener noreferrer">Contact our team.</a>
            </Card.Text>
            
        </div>
    )
}