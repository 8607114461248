import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

export default function CardPublicMint({tokensRemaining, publicMint, mintPrice}) {
    const [quantity, setQuantity] = useState("");

    const handleChange = (e) => {
        e.preventDefault();
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setQuantity(e.target.value);
        }   
    }

    return (
        <>
            <Card.Header as="h3" style={{textAlign: 'center', marginBottom: '1rem' }}>Join the Pig Pen</Card.Header>
            <Card.Text>Mint your Capitalist Pig NFTs for {parseInt(mintPrice)} ETH each, and get access to the private investor community, deal flow, and other VIP perks.</Card.Text>
            <Form>
                <Form.Control type="text" placeholder="How many would you like to mint?" value={quantity} onChange={(e) => handleChange(e)} />
            </Form>
            { tokensRemaining === 0 ?
                <Button variant="primary" disabled style={{margin: 'auto', marginTop: '1rem' }}>No Tokens Remaining</Button> : 
                <Button variant="primary" disabled={quantity === '' || parseInt(quantity) > tokensRemaining ? true : false} style={{margin: 'auto', marginTop: '1rem' }} onClick={() => publicMint(quantity)}>
                    { quantity === '' ? 'Enter a Quantity to Mint' : '' }
                    { quantity !== "" && parseInt(quantity) <= tokensRemaining ? `Mint Your ${quantity} Capitalist Pig NFT${quantity > 1 ? 's' : ''}: ${quantity * mintPrice} ETH` : '' }
                    { quantity !== "" && parseInt(quantity) > tokensRemaining ? `Sorry, there are only ${tokensRemaining} tokens left to mint.` : '' }        
                </Button>
            }
            
        </>
    )
}

