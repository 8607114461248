import { Card, CardGroup, Form, Button, Alert } from 'react-bootstrap';

export default function CardThankYou2({tokenIds, contractAddr}) {
    return (
        <>
            <Card.Header as="h3" style={{textAlign: 'center', marginBottom: '1rem' }}>Welcome to the Capitalist Pigs!</Card.Header>
            <CardGroup>
                <Card>
                    <Card.Body>
                        <a href={`http://opensea.io/assets/${contractAddr}/${tokenIds[0]}`} target="_blank" rel="noopener noreferrer"><Card.Img variant="bottom" src={`0${tokenIds[0] > 9 ? '' : '0'}${tokenIds[0]}.png`} /></a>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Text style={{textAlign: 'center'}}>
                            <a href={`http://opensea.io/assets/${contractAddr}/${tokenIds[0]}`} target="_blank" rel="noopener noreferrer">
                                Congratulations! You are now the proud owner of Pig{ tokenIds.length > 1 ? `s #${tokenIds.join(', ')}` : ` #${tokenIds[0]}`}.   
                            </a>
                        </Card.Text>
                        <Card.Text>Click the link above to see your Pig on OpenSea, where you can verify ownership, track the floor price, or list it for sale (but you would never do that, would you?)</Card.Text>
                        <Alert variant="primary"><a style={{fontStyle: "italic", textDecoration: "none"}} href="https://www.capitalism.com/nft-post-purchase-provide-your-info/" target="_blank" rel="noopener noreferrer">
                        THE FINAL STEP:  Click here and provide us with your contact information to get group announcements and participate in the Discord community!
                        </a></Alert>
                        <Card.Text>For support, please reach out to us: support@capitalism.com</Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>     
        </>
    )
}